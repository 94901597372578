::-webkit-scrollbar {
    width: 6px !important;
  } 

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.23) !important; 
    border-radius: 24px;
    transition: all 0.5s;
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(146, 151, 165, 1) !important; 
  }

body {
    scrollbar-color: #267871 transparent;
    scrollbar-width: thin !important;
    overflow-x: hidden;
    height: 100vh;
    background-color: #ebeff3;
    /* font-family: 'Nunito', sans-serif; */
    font-family: "PT Sans", sans-serif;
    /* font-family: "fontRegular" !important; */
    /* font-family: "fontLight"; */
    /* font-family: "fontBold"; */
}

html {
    scrollbar-color: #267871 transparent;
    scrollbar-width: thin !important;
    /* font-family: 'Nunito', sans-serif; */
    font-family: "PT Sans", sans-serif;
}

* {
    scrollbar-color: #267871 transparent;
    scrollbar-width: thin !important;
    /* font-family: 'Nunito', sans-serif; */
    font-family: "PT Sans", sans-serif;

}